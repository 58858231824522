import React from "react";
import Link from "next/link";

const ShowroomListing = ({ showrooms }) => {
  return (
    <div className="s-container">
      <h2
        className="text-center"
        style={{ fontSize: "1.1rem", marginBottom: 20 }}
      >
        Xu hướng kinh doanh 2021
      </h2>
      <dl>
        {showrooms.map(showroom => (
          <div key={showroom.slug} className="mb-2">
            <dt>
              <Link
                href={`/showroom/showroom-details?slug=${showroom.slug}`}
                as={`/showroom/${showroom.slug}.html`}
                className="showroom"
              >
                {showroom.title}
              </Link>
            </dt>

            {showroom.childs.length > 0 && (
              <div className="flex flex-wrap">
                {showroom.childs.map(child => (
                  <dd key={child.id}>
                    <Link
                      href={`/showroom/showroom-details?slug=${child.slug}`}
                      as={`/showroom/${child.slug}.html`}
                      className="showroom-child"
                    >
                      {child.title}
                    </Link>
                  </dd>
                ))}
              </div>
            )}
          </div>
        ))}
      </dl>
      <style jsx>{`
        .s-container {
          background-color: #fff;
          padding: 10px;
        }
        .showroom {
          color: #333;
        }
        .showroom-child {
          color: #333;
          font-size: 0.875rem;
          padding-right: 5px;
          border-right: 1px solid rgba(0, 0, 0, 0.2);
          margin-right: 5px;
        }
      `}</style>
    </div>
  );
};

export default React.memo(ShowroomListing);
